.kyc-alert {
    margin-top: 20px;
    background-color: #f8d7da; /* Light red background to indicate danger */
    color: #721c24; /* Dark red text color */
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #f5c6cb; /* Slightly darker red border */
    text-align: center; /* Center the content */
    font-weight: bold; /* Make the text bold */
    width: 90%; /* Make the alert box responsive */
    max-width: 600px; /* Maximum width for larger screens */
    margin: 20px auto; /* Center the alert box */
    box-sizing: border-box; /* Ensure padding is included in width */
}

.kyc-alert .danger-button {
    background-color: #dc3545; /* Bootstrap danger red */
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold; /* Make the button text bold */
    width: 100%; /* Make the button full width */
    margin-top: 10px; /* Add some margin on top */
    text-transform: uppercase; /* Make the text uppercase */
    box-sizing: border-box; /* Ensure padding is included in width */
}

.kyc-alert .danger-button:hover {
    background-color: #c82333; /* Darken the button color on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .kyc-alert {
        padding: 10px;
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .kyc-alert .danger-button {
        padding: 10px 20px;
        font-size: 16px; /* Adjust button font size */
    }
}

@media (max-width: 480px) {
    .kyc-alert {
        padding: 8px;
        font-size: 12px; /* Further adjust font size for very small screens */
    }

    .kyc-alert .danger-button {
        padding: 8px 16px;
        font-size: 14px; /* Further adjust button font size */
    }
}
