/* Container */
.dashboard-container {
    display: flex;
    height: 100vh;
    background-color: #f0f0f0;
    overflow: hidden;
}

.sidebar {
    width: 270px; /* Updated width */
    background-color: #3C009D;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    transition: width 0.3s;
    overflow: hidden;
}

.sidebar.closed {
    width: 70px; /* Keep the collapsed width */
}

.sidebar .top-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar .top-section .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar .top-section .logo img {
    width: 100%;
    max-width: 150px;
    border-radius: 20px;
    transition: width 0.3s ease;
}

.sidebar.closed .top-section .logo img {
    width: 40px;
}

.sidebar .top-section .toggle-button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.sidebar .nav-links {
    list-style: none;
    padding: 0;
    width: 100%;
    margin-top: 20px;
}

.sidebar .nav-links li {
    margin: 10px 0;
    width: 100%;
    position: relative;
}

.sidebar .nav-links li a {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s;
}

.sidebar .nav-links li a:hover {
    background-color: #52227C;
}

.sidebar .nav-links li a .icon {
    font-size: 24px;
}

.sidebar .nav-links li a span {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: opacity 0.3s ease;
}

.sidebar.open .nav-links li a span {
    opacity: 1;
}

.sidebar.closed .nav-links li:hover span {
    opacity: 1;
}

/* Main Content */
.main-content {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}

.header .user-info {
    display: flex;
    justify-content: space-between; /* Aligns items on both ends */
    width: 100%;
    align-items: center;
}

.user-info .user-details {
    display: flex;
    align-items: center;
}

.user-info .user-details h6 {
    margin: 0;
    color: #3C009D;
}

.user-info .user-avatar-container {
    display: flex;
    align-items: center;
    position: relative;
}

.user-avatar {
    cursor: pointer;
    margin-left: 15px;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50px; /* Adjust as needed */
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.dropdown-item {
    background: #3C009D;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s;
}

.dropdown-item:hover {
    background-color: #52227C;
}
.account-cards {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin: 15px 0;
}

.account-card {
    background: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    /* width: 120px; */
    display: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.account-number {
    font-weight: bold;
}

.account-bank {
    color: #888;
}

.balance-button {
    background-color: #3C009D;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.balance-button:hover {
    background-color: #52227C;
}

.header .text {
    color: #3C009D;
    font-size: 36px;
    font-weight: 700;
}

.header .underline {
    width: 61px;
    height: 4px;
    background-color: #3C009D;
    border-radius: 2px;
    margin-top: 10px;
}

.user-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.user-card .account-details {
    display: flex;
    gap: 20px; /* Space between cards */
    overflow-x: auto; /* Horizontal scroll if needed */
}

.user-card .account-details {
    background: #f5f5f5;
    border-radius: 10px;
    padding: 15px;
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.user-card .account-details .account-card p {
    margin: 0;
}

.user-card .account-details .account-card .account-number {
    font-weight: bold;
}

.user-card .account-details .account-card .account-bank {
    color: #888;
}

.copy-icon {
    cursor: pointer;
    color: #3C009D;
    transition: color 0.3s;
}

.copy-icon:hover {
    color: #52227C;
}

.footer {
    text-align: center;
    padding: 10px;
    background-color: #3C009D;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .sidebar {
        width: 250px; /* Adjust for smaller screens */
    }

    .sidebar .nav-links li a {
        font-size: 14px;
        padding: 8px;
    }

    .sidebar .nav-links li a .icon {
        font-size: 20px;
    }

    .header .text {
        font-size: 28px;
    }

    .header .user-info .user-avatar {
        width: 40px;
        height: 40px;
    }

    .header .user-info .user-details h6 {
        font-size: 18px;
    }

    .header .user-info .user-details p {
        font-size: 14px;
    }

    .user-card .account-details .account-card {
        width: 200px; /* Adjust card width on smaller screens */
    }
}

@media (max-width: 480px) {
    .sidebar {
        width: 200px; /* Adjust for very small screens */
    }

    .sidebar.closed {
        width: 50px;
    }

    .sidebar .nav-links li a {
        font-size: 12px;
        padding: 6px;
    }

    .sidebar .nav-links li a .icon {
        font-size: 22px; /* Adjusted size */
    }

    .header .text {
        font-size: 24px;
    }

    .header .user-info .user-avatar {
        width: 30px;
        height: 30px;
    }

    .header .user-info .user-details h6 {
        font-size: 16px;
    }

    .header .user-info .user-details p, 
    .user-card .account-details ul {
        font-size: 12px;
    }
    .user-card .account-details .account-card {
        width: 160px; /* Adjust card width on smaller screens */
    }
}
.account-section {
    margin-top: 20px;
}

.toggle-accounts-button {
    background-color: blue;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s;
    width: 200px;
}

.toggle-accounts-button:hover {
    background-color: #52227C;
}


.kycForm{
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    overflow-x: auto; /* Enables horizontal scroll */
    overflow-y: auto; /* Enables vertical scroll */
    padding: 0 10px; /* Add some horizontal padding to avoid clipping */
    background-color: #fff;
    border-radius: 10px; /* Add some border radius for aesthetics */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-right: 20px;
    max-height: 400px; /* Set a max height to trigger vertical scroll */
}

.profile-picture {
    cursor: pointer;
    margin-left: 15px;
    width: 40px;
    height: 40px;
    border-radius: 10px; 
    object-fit: cover; /* Ensures the image covers the container without distortion */
    transition: width 0.3s, height 0.3s; /* Smooth transition for resizing */
}

@media screen and (max-width: 768px) {
    .profile-picture {
        width: 35px;
        height: 35px;
    }
}

@media screen and (max-width: 480px) {
    .profile-picture {
        width: 30px;
        height: 30px;
        margin-left: 10px;
    }
}

/***page content***/
.page-content {
    margin: 0 auto;
    /* padding: 5px; */
    /* text-align: center; */
    background-color: #f8f8f8;
    border-radius: 8px;
    max-width: 100%; /* Ensure it fits within the viewport */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.page-content h1 {
    font-size: 24px; /* Base font size */
    margin-bottom: 30px;
    color: #333;
}

/* Medium screens */
@media (max-width: 768px) {
    .page-content {
        padding: 15px; /* Adjust padding for smaller screens */
        max-width: 95%; /* Increase width to better fit the screen */
    }

    .page-content h1 {
        font-size: 20px; /* Reduce font size for smaller screens */
    }
}

/* Small screens */
@media (max-width: 480px) {
    .page-content {
        padding: 10px; /* Further adjust padding for very small screens */
        max-width: 100%; /* Full width for very small screens */
    }

    .page-content h1 {
        font-size: 18px; /* Smallest font size */
        margin-bottom: 15px; /* Adjust margin for better spacing */
    }
}
