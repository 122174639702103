.profileForm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.left-column, .right-column {
    flex: 1;
    margin: 0 5px;
}

.left-column {
    max-width: 300px; /* You can adjust the width based on your design */
}

.right-column {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 0px; /* Adds spacing between form groups */
}

.form-group {
    display: flex;
    flex-direction: column;
}

.submit-button {
    align-self: flex-end; /* Align the button to the right */
    margin-top: 20px;
}

.spinner {
    margin-right: 10px; /* Adds space between spinner and text */
}

.passport-preview {
    margin-top: 10px;
}

.prev-img {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
}
