.kycForm {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 100%;
    margin: 12px auto;
    box-sizing: border-box;
}

.kycForm .form-group {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.kycForm label {
    font-weight: bold;
    margin-bottom: 0px;
}

.kycForm input[type="text"], input[type="file"], input[type="date"],
.kycForm select,
.kycForm textarea {
    width: 100%;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.kycForm textarea {
    resize: vertical;
}

.kycForm .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-bottom: 30px;
}

.kycForm .submit-button:hover {
    background-color: #0056b3;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
    .kycForm {
        width: 95%;
        padding: 15px;
    }

    .kycForm .submit-button {
        padding: 12px 0;
    }
}

@media (max-width: 480px) {
    .kycForm {
        width: 100%;
        padding: 10px;
    }

    .kycForm .submit-button {
        font-size: 14px;
        padding: 10px 0;
    }

    .kycForm input[type="text"],
    .kycForm select,
    .kycForm textarea {
        font-size: 14px;
    }
}
