/***transaction table**/
.transactionTable {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    overflow-x: auto; /* Enables horizontal scroll */
    overflow-y: auto; /* Enables vertical scroll */
    padding: 0 10px; /* Add some horizontal padding to avoid clipping */
    background-color: #fff;
    border-radius: 10px; /* Add some border radius for aesthetics */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 400px; /* Set a max height to trigger vertical scroll */
}

/* Ensure the table itself scales properly */
.transactionTable table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    table-layout: fixed; /* Ensures columns are evenly spaced */
}

.transactionTable th,
.transactionTable td {
    padding: 8px 10px; /* Reduced padding for compactness */
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px; /* Base font size */
    word-wrap: break-word; /* Ensure long text wraps properly */
}

.transactionTable th {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 600;
}

.transactionTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

.transactionTable tr:hover {
    background-color: #f1f1f1;
}

.transactionTable td {
    color: #333;
}

.transactionTable th[colspan] {
    text-align: center;
    font-size: 16px; /* Adjusted for compactness */
    font-weight: bold;
    border-bottom: 2px solid #fff;
}

.transactionTable td:first-child {
    font-weight: 500;
    color: #3C009D;
}

/* Medium screens */
@media (max-width: 768px) {
    .transactionTable {
        max-width: 90%; /* Reduce max width for medium screens */
    }

    .transactionTable th,
    .transactionTable td {
        padding: 6px 8px; /* Further reduced padding for smaller screens */
        font-size: 12px; /* Further reduced font size */
    }

    .transactionTable th[colspan] {
        font-size: 14px; /* Adjusted font size */
    }
}

/* Small screens */
@media (max-width: 480px) {
    .transactionTable {
        max-width: 100%; /* Full width for small screens */
    }

    .transactionTable th,
    .transactionTable td {
        padding: 4px 6px; /* Even further reduced padding */
        font-size: 10px; /* Smallest font size */
    }

    .transactionTable th[colspan] {
        font-size: 12px; /* Adjusted for smallest screens */
    }
}
