/* Container */
.container {
    display: flex;
    flex-direction: column;
    margin: 60px auto;
    /* margin-top: 0px; */
    width: 90%; /* Use percentage instead of fixed width */
    max-width: 600px; /* Set a maximum width */
    background-color: #fff;
    padding-bottom: 53px;
    border-radius: 10px; /* Add some border radius for aesthetics */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 500);
}

/* Header */
.header-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
    margin-top: 7px;
}

/* Text */
.text {
    color: #3C009D;
    font-size: 40px; /* Adjusted font size for smaller screens */
    font-weight: 700;
}

/* Underline */
.underline {
    width: 61px;
    height: 6px;
    background: #3C009D;
    border-radius: 9px;
}

/* Inputs */
.inputs {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Input */
.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%; /* Full width within the container */
    max-width: 480px; /* Maximum width */
    height: 40px;
    background: #eaeaea;
    border-radius: 6px;
    position: relative; /* Add position relative for the eye icon */
}

.input img.toggle-password {
    position: absolute; /* Position the eye icon inside the input field */
    right: 15px; /* Align it to the right */
    cursor: pointer; /* Add pointer cursor */
    width: 20px;
    height: 20px;
}

.input img {
    margin: 0px 15px; /* Reduce margin for smaller screens */
}

.input input {
    height: 20px;
    width: 100%; /* Full width for input */
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 16px; /* Adjusted font size */
}

/* Forgot Password */
.forgot-password, .terms {
    padding-left: 60px; /* Adjust padding */
    margin-top: 20px;
    color: #797979;
    font-size: 14px; /* Adjust font size */
}

/* Submit Container */
.submit-container {
    display: flex;
    flex-direction: column; /* Stack buttons on top of each other on small screens */
    /* gap: 20px; */
    margin: 40px auto;
    width: 100%; /* Ensure buttons take full width */
    max-width: 480px; /* Maximum width */
}

/* Submit Button */
.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 90%; /* Full width for buttons */
    height: 50px; /* Adjust height */
    color: #fff;
    background-color: #4C004B;
    border-radius: 40px;
    font-size: 18px; /* Adjusted font size */
    font-weight: 700;
    cursor: pointer;
}
.login-signup{
    padding-left: 60px; /* Adjust padding */
    margin-top: -15px;
    margin-bottom: 30px;
    color: #797979;
    font-size: 14px; /* Adjust font size */
}
.success-back{
    display: flex;
    margin: 0px auto;
    /* margin-top: -15px;
    margin-bottom: 30px; */
    color: #797979;
    font-size: 16px;
}

/* Gray Button */
.gray {
    background: #EAEAEA;
    color: #676767;
}
.footer{
    text-align: center;
    font-size: 14px;
    color:#fff;
}
.logo{
    text-align: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.logo img{
    font-size: small;
    width: 150px;
    border-radius: 20px
}
.error{
    display: flex;
    color: red;
}
.succes-page{
    text-align: center;
}
.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    display: inline-block;
    margin-right: 8px;
  }
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .text {
        font-size: 28px;
    }

    .input img {
        margin-right: 15px;
        width: 20px;
        height: 20px;
    }

    .input input {
        font-size: 14px;
    }

    .forgot-password {
        font-size: 14px;
    }

    .submit {
        height: 45px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .text {
        font-size: 24px;
    }

    .input {
        height: 50px;
    }

    .submit-container {
        margin: 20px auto;
    }

    .submit {
        height: 40px;
        font-size: 14px;
    }
}
